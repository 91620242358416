<template>
    <v-list-item dense @click="navigateTo(`/app/products/view/${product._id}`)">
        <v-list-item-icon>
            <image-card height="64" width="64" :src="product.images[0]?.path"></image-card>
        </v-list-item-icon>
        <v-list-item-content class="ml-n4">
            <v-list-item-title class="text-body-2 font-weight-medium text-wrap">{{ product.title
                }}</v-list-item-title>
            <v-list-item-subtitle class="font-weight-light">{{ product.code }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="product.hasVariant">{{ product.variantsAvailable.length }}
                variants</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-menu v-if="icons" left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in icons" :key="index">
                        <v-btn fab dark x-small class="ma-2" :style="theme">
                            <v-icon :show="isAllowed(item.permission)" @click="$emit('action', item, product)">{{
                                item.name
                            }}</v-icon>
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-list-item-subtitle class="text-body-1">Rs. {{ product.price }}/-</v-list-item-subtitle>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import ImageCard from './ImageCard.vue';
export default {
    components: { ImageCard },
    props: ["icons", "product"],
};
</script>